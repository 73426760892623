<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFC850;"
      d="M483.556,75.852H266.155c-12.572,0-24.632-4.994-33.522-13.886l-29.118-29.118
	c-8.891-8.891-20.949-13.886-33.522-13.886H28.444C12.735,18.963,0,31.698,0,47.407v113.778h512v-56.889
	C512,88.587,499.265,75.852,483.556,75.852z"
    />
    <path
      style="fill:#E1A546;"
      d="M512,123.501c-7.948-6.016-17.73-9.723-28.444-9.723H28.444c-10.714,0-20.497,3.707-28.444,9.723
	v37.684h512V123.501z"
    />
    <path
      style="fill:#FFDC64;"
      d="M483.556,493.037H28.444C12.735,493.037,0,480.302,0,464.593V161.185
	c0-15.71,12.735-28.444,28.444-28.444h455.111c15.71,0,28.444,12.735,28.444,28.444v303.407
	C512,480.302,499.265,493.037,483.556,493.037z"
    />
    <circle style="fill:#FFC850;" cx="256" cy="312.889" r="132.741" />
    <circle style="fill:#FFF082;" cx="256" cy="312.889" r="113.778" />
    <g>
      <circle style="fill:#FFC850;" cx="256" cy="265.481" r="28.444" />
      <path
        style="fill:#FFC850;"
        d="M286.79,315.013c-3.596-3.068-8.813-2.436-12.418,0.621c-4.954,4.202-11.366,6.737-18.372,6.737
		s-13.417-2.535-18.373-6.737c-3.605-3.057-8.823-3.688-12.418-0.621c-10.138,8.652-16.616,21.42-16.616,35.802v18.963
		c0,5.236,4.245,9.482,9.481,9.482h75.852c5.236,0,9.481-4.245,9.481-9.482v-18.963C303.407,336.433,296.929,323.665,286.79,315.013
		z"
      />
    </g>
    <g>
      <path
        style="fill:#E1A546;"
        d="M237.037,369.778v-18.963c0-11.345,4.072-21.649,10.751-29.756
		c-3.763-1.14-7.236-2.944-10.161-5.425c-3.605-3.057-8.823-3.688-12.418-0.621c-10.138,8.652-16.616,21.42-16.616,35.802v18.963
		c0,5.236,4.245,9.482,9.481,9.482h28.444C241.282,379.259,237.037,375.014,237.037,369.778z"
      />
      <path
        style="fill:#E1A546;"
        d="M274.963,284.444c-15.71,0-28.444-12.735-28.444-28.444c0-7.211,2.77-13.719,7.192-18.732
		c-14.619,1.185-26.155,13.288-26.155,28.213c0,15.71,12.735,28.444,28.444,28.444c8.499,0,16.04-3.802,21.253-9.713
		C276.485,284.276,275.748,284.444,274.963,284.444z"
      />
    </g>
    <path
      style="fill:#FFDC64;"
      d="M39.668,132.741h-0.233C39.511,132.915,39.588,132.966,39.668,132.741z"
    />
    <path
      style="fill:#FFC850;"
      d="M189.63,474.074c-73.311,0-132.741-59.43-132.741-132.741V132.741H28.444
	C12.735,132.741,0,145.476,0,161.185v303.407c0,15.71,12.735,28.444,28.444,28.444h455.111c12.367,0,22.784-7.941,26.702-18.963
	H189.63z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
